// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section-mobile
// =========================================================================
.lead-section-mobile {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			background: $green;
			border: none;
			border-radius: 0;

			.card-body {
				position: relative;
				padding: 15px 20px 30px 20px;

				@include media-breakpoint-up(xl) {
					padding: 20px 30px 35px 30px;
				}

				.card-caption {
					.card-title-link {
						position: absolute;
						left: 0;
						right: 30px;
						bottom: 100%;
						max-width: 300px;
						margin: 0 0 30px 0;
						padding: 15px;
						background: rgba($green-dark, 0.6);

						.card-title {
							margin: 0;
							color: $white;
							line-height: 1.05;
							@include font-size($h1-font-size);
						}
					}

					.card-subtitle {
						margin: 0;
						color: $red;
						font-size: 14px;
						font-weight: 700;
						font-family: $font-family-primary;
					}

					.card-description {
						margin: 20px 0;
						color: $white;

						strong {
							color: $green-dark;
							@extend .h2;
						}

						ul {
							@extend .custom-list;

							li {
								margin-bottom: 0 !important;
							}
						}
					}
				}

				.card-buttons {
					.card-badge {
						position: absolute;
						right: 15px;
						bottom: calc(100% - 50px);
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100px;
						height: 100px;
						padding: 5px;
						border-radius: 100%;
						background-color: $red;
						color: $white;
						font-size: 12px;
						font-weight: 700;
						text-align: center;
						text-decoration: none;
						overflow: hidden;

						&:hover {
							background-color: $green-dark;
						}
					}
				}
			}
		}
	}
}


// offcanvas-collection-section
// =========================================================================
.offcanvas-collection-section {
	padding: 6vh 0;

	.container-fluid {
		.container-holder {
			@include media-breakpoint-up(xl) {
				padding-left: calc((100vw / 2 - 575px));
			}

			.column {
				padding-right: 0;
			}
		}
	}

	// collection
	.collection {
		&.slider {
			.owl-stage {
				padding-left: 0 !important;
			}

			.card {
				@extend .default-card;
			}
		}
	}
}

// reviews-section
// =========================================================================
.reviews-section {
	padding: 6vh 0;
	text-align: center;

	.review-create {
		a.btn {
			padding: 0;
			border: none;
			background: none;
			color: $red;
			font-size: 16px;

			&:hover {
				background: none;
				color: darken($red, 50%);
			}
		}

		form {
			text-align: left;

			.rating {
				color: $green;
				font-size: 24px;

				i {
					margin-right: 5px;
				}
			}
		}
	}

	// collection
	.collection {
		margin-bottom: 30px;

		&.owl-carousel {
			padding: 0 30px;

			&::before {
				@extend .d-none;
			}

			.item {
				.card {
					width: 100%;
					border: none;

					.card-body {
						padding: 0;

						.review {
							display: flex;
							flex-direction: column;

							.rating {
								order: -1;
								margin-bottom: 15px;
								color: $green;
								font-size: 24px;

								i {
									margin-right: 5px;
								}
							}

							.comment {
								margin-bottom: 15px;
							}

							.reviewer {
								font-family: $font-family-secondary;

								@include media-breakpoint-up(xl) {
									font-size: 20px;
								}
							}

							.date {
								font-size: 0.85rem;
							}
						}
					}

					.card-buttons {
						display: none;
					}
				}
			}

			// owl-nav
			.owl-nav .owl-prev {
				left: -30px;

				@include media-breakpoint-down(md) {
					left: 0;
				}
			}

			.owl-nav .owl-next {
				right: -30px;

				@include media-breakpoint-down(md) {
					right: 0;
				}
			}

			.owl-nav .owl-prev,
			.owl-nav .owl-next {
				width: 30px;
				height: 30px;
				color: $green;
				font-size: 24px;
				line-height: 30px;

				&:hover {
					color: $red;
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
