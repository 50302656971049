&.nieuwe-formitable-page,
&.extra-werkenbij-page,
&.werkenbij-page {

	// header
	.header-werkenbij {
		padding: 45px 0;
		text-align: center;
	}

	// content-block-section
	.content-block-section {
		&:nth-of-type(odd) {
			background-color: $brown-light;
		}

		.container-fluid {
			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(lg) {
							@include make-col(7);
						}

						&:has(.resource-image) {
							@include media-breakpoint-up(lg) {
								@include make-col(5);
							}
							@include media-breakpoint-down(sm) {
								order: 2;
							}
						}
					}
					&.two {
						@include media-breakpoint-up(lg) {
							@include make-col(7);
						}
						&:has(.resource-image) {
							@include media-breakpoint-up(lg) {
								@include make-col(5);
							}
							@include media-breakpoint-down(sm) {
								order: 2;
							}
						}
					}
				}
			}
		}

		.container-fluid {
			.container-holder {
				margin-bottom: 0;

				.column {
					margin-bottom: 0;
					padding: 0 !important;
					justify-content: center;

					.wysiwyg {
						margin: 30px;

						@include media-breakpoint-only(lg) {
							margin: 45px;
						}

						@include media-breakpoint-up(xl) {
							margin: 60px;
						}
					}

					.resource-image {
						height: 100%;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
	}

	// jump-to-apply
	a.jump-to-apply {
		z-index: 997;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 30px 0 20px 0;
		background: $green-dark;
		color: $white;
		font-size: 12px;
		font-weight: 700;
		text-align: center;
		text-decoration: none;
		box-shadow: 0 -3px 6px rgba($black, 0.15);

		&::before {
			content: "\f0a7";
			position: absolute;
			top: -30px;
			left: 0;
			right: 0;
			width: 60px;
			height: 60px;
			margin: 0 auto;
			border-radius: 100%;
			background: $green-dark;
			font-size: 26px;
			font-weight: 300;
			text-align: center;
			line-height: 60px;
			font-family: $font-awesome;
			transition: 0.5s;
		}

		&:hover {
			background: lighten($red, 5%);

			&::before {
				background: lighten($red, 5%);
			}
		}
	}

	// footer
	.footer-werkenbij {
		position: relative;
		z-index: 998;
		padding-top: calc(70px + 6vh);
		padding-bottom: 6vh;
		background: $green-dark;
		color: $white;

		// jump-to-apply
		a.jump-to-apply {
			position: absolute;
			top: 0;
			bottom: auto;
			box-shadow: none;
			pointer-events: none;
		}

		.container-two-columns {
			.container-holder {
				.column {
					&.one {
						@include media-breakpoint-up(md) {
							@include make-col(4);
						}
					}

					&.two {
						@include media-breakpoint-up(md) {
							@include make-col(8);
						}
					}
				}
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		li {
			color: $white;
		}

		a:not(.btn):not(.link) {
			color: $white;
			text-decoration: underline;

			&:hover {
				color: $black;
			}
		}

		// form
		.form {
			padding: 0;
			border: none;

			button {
				@extend .btn-secondary;

				&:hover {
					background-color: $green !important;
					border-color: $green !important;
					color: $white !important;
				}
			}
		}
	}
}
