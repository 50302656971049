.arrangement-collection {
    .owl-nav {
        display: none !important;
    }

    .owl-dots {
        @include media-breakpoint-up(md) {
            @include make-col(5);
            width: 100%;
            padding: 0 40px;
            left: auto;
            justify-content: flex-start;
        }

        @include media-breakpoint-up(xl) {
            @include make-col(4);
            padding: 0 80px;
        }
    }

    .card.card-wide {
        .card-image {
            @include media-breakpoint-up(md) {
                @include make-col(7);
            }

            @include media-breakpoint-up(xl) {
                @include make-col(8);
            }

            .card-image-link {
                height: 100%;
            }

            img {
                max-height: 600px;
                height: 100%;
            }
        }

        .card-body {
            padding: 40px 40px 60px;

            @include media-breakpoint-up(md) {
                @include make-col(5);
                padding: 40px;
            }
            
            @include media-breakpoint-up(xl) {
                @include make-col(4);
                padding: 30px 60px;
            }

            background-color: $green-dark;
            justify-content: center;
        }

        .card-caption {
            flex-grow: 0;
            color: $white;
            display: flex;
            flex-direction: column;

            .card-title {
                color: $green;
                font-size: 2rem;

                @include media-breakpoint-up(lg) {
                    font-size: 2.5rem;
                }
            }

            .card-subtitle {
                order: -1;
                font-size: 1rem;
            }
        }

        .card-buttons {
            margin-top: 0;
        }
    }
}

.collection.slider {
    &:not(.reviews-slider) {
        .owl-nav {
            display: none !important;
        }
    }
}