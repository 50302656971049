// dark
.bg-dark {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $text-light;
	}

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			&::before {
				color: $text-light;
			}
		}
	}
}
