// header
.header {
	z-index: 998;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	// box-shadow: $shadow;
	background-color: transparent;

	&.sticky {
		position: fixed;
		box-shadow: $shadow;
		background-color: $white;
	}

	// header-top
	.header-top {
		padding: 30px 15px;
		// background-color: $white;
		transition: 0.3s;

		@include media-breakpoint-down(md) {
			padding: 30px 0;
			height: auto !important;
		}

		.container-holder {
			>.column {
				align-items: center;
				justify-content: space-between;
				@extend .navbar-expand;

				@include media-breakpoint-down(md) {
					align-items: flex-start;
					flex-wrap: wrap;
					padding: 0;
				}
			}
		}

		// menu
		.menu {
			flex: 1 1 100%;
			width: 100%;

			.navbar-nav {
				align-items: flex-start;
				width: 100%;

				>.nav-item {
					margin: 0 10px;
					font-size: 12px;
					font-weight: 700;
					line-height: 1;

					@include media-breakpoint-up(md) {
						margin: 0 15px;
						font-size: 14px;
					}

					&:first-of-type {
						@include media-breakpoint-up(lg) {
							margin-left: 0;
						}

						a {
							font-size: 12px;

							@include media-breakpoint-up(md) {
								font-size: 16px;
							}

							&::before {
								content: '\f015';
								font-family: $font-awesome;
								font-weight: 900;

								@include media-breakpoint-down(md) {
									content: '';
								}
							}

							.menu-title {
								display: none;

								@include media-breakpoint-down(md) {
									display: inline;
								}
							}
						}
					}

					>.nav-link {
						padding: 0.5rem 0 5px;
						border-bottom: 1px solid transparent;
						color: $white;

						// &:hover {
						// 	border-color: $green-dark;
						// 	color: $green-dark;
						// }

						&::after {
							display: none;
						}
					}

					&.active,
					&:hover {
						>.nav-link {
							border-color: $green-dark;
						}
					}

					&:last-of-type {
						margin-left: auto;
					}
				}

				.nav-item {
					&.dropdown {
						&.show {
							@include media-breakpoint-down(md) {
								position: static;
							}
						}
					}

					.dropdown-menu {
						// position: relative !important;
						top: 100% !important;
						left: 0 !important;
						transform: translateX(0) !important;

						@include media-breakpoint-down(md) {
							right: 0;
							text-align: left;
						}

						margin: 15px 0;
						padding: 15px 0;
						background: transparent;
						border: none;
						border-radius: 0;
						box-shadow: none;

						&::before,
						&::after {
							display: none;
						}

						// @include media-breakpoint-down(md) {
						// 	&::before {
						// 		content: '\f057';
						// 		display: block;
						// 		position: absolute;
						// 		top: 0;
						// 		right: 15px;
						// 		font-family: $font-awesome;
						// 		color: $primary;
						// 		font-size: 15px;
						// 		font-weight: 900;
						// 	}
						// }

						.nav-item {
							font-size: 16px;

							@include media-breakpoint-down(md) {
								font-size: 14px;
							}

							@include media-breakpoint-down(sm) {
								font-size: 12px;
							}

							line-height: 1.2;
							white-space: nowrap;
							font-weight: 500;
							font-family: $font-family-secondary;

							.nav-link {
								padding: 0.5rem 0;
								color: $white;

								&:hover {
									color: $green;
								}
							}

							&.active {
								.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}

			// mobile
			@include media-breakpoint-down(md) {
				order: 1;
				flex: 1 0 100%;
				padding-top: 10px;
				padding-top: 0;
				height: 0;
				overflow: hidden;

				&.show {
					padding-top: 10px;
					height: auto;
				}

				.navbar-nav {
					flex-direction: column !important;

					.nav-item {
						&.last {
							margin-left: 10px;
						}

						.dropdown-menu {
							position: relative !important;
							padding-left: 1rem;
							margin: 0;

							.nav-item {
								&.last {
									margin-left: 0;
								}
							}
						}
					}
				}
			}
		}

		// quick-contact
		div.quick-contact {
			margin-left: auto;

			ul {
				li {
					font-size: 12px;

					@include media-breakpoint-up(md) {
						font-size: 16px;
					}

					a {
						display: block;
						width: 30px;
						height: 30px;
						// color: $green;
						line-height: 30px;
						text-align: center;
						color: $white;

						.list-item-title {
							display: none;
						}
					}
				}
			}
		}

		// quick-social
		div.quick-social {
			margin-left: 5px;

			@include media-breakpoint-up(md) {
				margin-left: 10px;
			}

			&::before {
				content: '\f1e0';
				display: block;
				width: 30px;
				height: 30px;
				color: $white;
				font-size: 12px;
				font-weight: 900;
				line-height: 30px;
				text-align: center;
				font-family: $font-awesome;
				transition: 0.5s;
				cursor: pointer;

				@include media-breakpoint-up(md) {
					font-size: 16px;
				}
			}

			ul {
				display: none !important;
				position: absolute;

				&.show {
					display: block !important;
				}

				li {
					a {
						display: block;
						width: 30px;
						height: 30px;
						text-align: center;
						color: $white;

						.list-item-title {
							display: none;
						}
					}
				}
			}
		}

		// site-switcher
		.site-switcher {
			margin-left: 15px;

			@include media-breakpoint-down(md) {
				margin-right: 10px;
			}

			ul {
				flex-wrap: nowrap !important;
			}
		}
	}
}


// logo
.logo {
	max-width: 270px;
	margin: 0 auto;
	position: absolute;
	left: 50%;
	top: 30px;

	@include prefix(transform, translateX(-50%));

	@include media-breakpoint-up(md) {
		max-width: 210px;
	}

	@include media-breakpoint-up(lg) {
		max-width: 310px;
	}

	a {
		@extend .d-block;
		transition: none;

		img {
			@extend .w-100;
		}
	}

	&.logo-text {
		transform: scale(0, 0);
		height: 0;
	}
}

&.sticky {
	.header-top {
		padding: 30px 15px;

		@include media-breakpoint-down(sm) {
			padding: 30px 0;
		}

		// menu
		.menu {
			.navbar-nav {
				>.nav-item {
					>.nav-link {
						color: $green;
						padding-bottom: 10px;
					}

					&.active,
					&:hover {
						.nav-link {
							color: $green-dark;
							border-color: $green-dark;
						}
					}

					.dropdown-menu {
						.nav-item {
							.nav-link {
								color: $green-dark;
							}
						}
					}
				}
			}
		}

		// .quick-contact
		div.quick-contact {
			ul {
				li {
					a {
						color: $green;

						&:hover {
							color: $green-dark;
						}
					}
				}
			}
		}

		// .quick-social
		div.quick-social {

			ul {
				li {
					a {
						color: $green;

						&:hover {
							color: $green-dark;
						}
					}
				}
			}

			&::before {
				color: $green;

			}

			&:hover {
				&::before {
					color: $green-dark;
				}
			}
		}
	}

	.logo {
		&.logo-visual {
			@include prefix(transform, scale(0, 0) translateX(-50%));
			height: 0;
		}

		&.logo-text {
			top: 30px;
			@include prefix(transform, scale(1, 1) translateX(-50%));
			height: auto;
		}
	}


	.menu-toggle {
		.navbar-toggler {
			.navbar-toggler-icon {
				color: $green;
			}
		}
	}
}

&:not(.home) {
	@include media-breakpoint-down(md) {
		.header-top {
			padding: 15px 0;
		}

		.logo {
			@include media-breakpoint-down(md) {
				top: 16px;
			}
		}

		.sticky {
			.logo {
				&.logo-visual {
					@include prefix(transform, scale(0, 0) translateX(-50%));
					height: 0;
				}

				&.logo-text {
					@include prefix(transform, scale(1, 1) translateX(-50%));
					height: auto;
				}

				@include media-breakpoint-down(md) {
					top: 16px;
				}
			}
		}
	}
}

.menu-toggle {
	.navbar-toggler {
		@include media-breakpoint-down(md) {
			display: block !important;
		}

		.navbar-toggler-icon {
			color: $white;
		}
	}
}

&.accommodation-search-book {
	.header {
		&.sticky {
			position: absolute !important;
		}
	}
}
