// guestplan-bar
.guestplan-bar {
	z-index: 998;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $green-dark;
	box-shadow: 0 -3px 6px rgba($black, 0.15);
	transition: 0.5s;

	// toggle-guestplan
	a.toggle-guestplan {
		display: block;
		padding: 20px 0 10px 0;
		color: $white;
		font-size: 12px;
		font-weight: 700;
		text-align: center;
		text-decoration: none;

		&::before {
			content: url('/images/guestplan-logo.svg');
			position: absolute;
			top: -30px;
			left: 0;
			right: 0;
			width: 60px;
			height: 60px;
			line-height: 60px;
			margin: 0 auto;
			padding-top: 10px;
			border-radius: 100%;
			background: $green-dark;
			text-align: center;
			transition: 0.5s;
			z-index: -1;
		}

	}
	&:hover {
		background: lighten($green-dark, 5%);

		a.toggle-guestplan {
			&::before {
				background: lighten($green-dark, 5%);
			}
		}
	}
}
