&.home {
    .content-section {
        .collection.contentblock.grid.page-block {
            .grid-items {
                .item {
                    flex: 1 1 100%;
                    max-width: 100%;
                    @include media-breakpoint-up(sm) {
                        flex: 1 1 50%;
                        max-width: 50%;
                    }

                    // right column
                    &:nth-child(1n + 2 ) {
                        @extend .pl-md-4;
                        @extend .pl-lg-5;
                    }

                    // left column
                    &:nth-child(2n + 1 ) {
                        @extend .pr-md-4;
                        @extend .pr-lg-5;
                    }

                    .card {
                        background: unset;

                        .card-image {
                            display: none;
                        }
                        .card-body {

                            padding: 0;
                            .card-caption {

                                .card-title-link {
                                    position: relative;
                                    inset: 0;
                                    max-width: 100%;
                                    margin: 0 0 0.75rem;
                                    padding: 0;
                                    background: unset;
                                    .card-title {
                                        color: $headings-color;
                                    }
                                }
        
                                .card-subtitle {
                                    display: none;
                                }

                                .card-description {
                                    color: $black;
                                    font-size: 1rem;
                                }
                            }
                        }

                        .card-btn {
                            @extend .btn-primary;
                        }
                    }
                }
            }
        }
    }
}