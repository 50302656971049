& {
	@include media-breakpoint-up(xl) {
		font-size: 18px;
	}
}

// headings
h1 {
	font-weight: 700;
}

h2 {
	margin-bottom: 1rem;
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

.container-fluid {
	max-width: 1920px;
}

// paragraph
// p {
// 	min-height: 1rem;
// }

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	background: $green-dark;
	border: none;
	border-radius: 0;

	.card-body {
		padding: 20px 20px 30px 20px;

		.card-caption {
			color: $white;
			font-size: 16px;
		}
	}
}

// link
.link {
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
}

// review > rating
.review-create,
.reviews-overview {
	.rating {
		color: $green !important;
	}
}

// modal
&.modal-open {
	overflow: visible;
	padding-right: 0 !important;

	.modal {
		padding-right: 0 !important;
	}
}

// notice-cookie-consent-general-terms
&.notice-cookie-consent-general-terms {
	.bundle-detail-section {
		.column {
			a {
				display: block;
				padding: 15px;
				border: 1px solid rgba($red, 0.15);
				border-radius: $border-radius;
				cursor: pointer;

				.list {
					position: relative;

					&::before {
						content: '\f111';
						position: absolute;
						top: 0;
						right: 0;
						color: $red;
						font-size: 30px;
						line-height: 1;
						font-weight: 300;
						font-family: $font-awesome;
						transition: 0.5s
					}

					.cookie-btn {
						@extend .btn-primary;
					}
				}
			}

			&.active,
			&:hover {
				a {
					border-color: $red;

					.list {
						&::before {
							content: '\f058';
						}
					}
				}
			}
		}
	}
}


.lead-section,
.content-section {

	.collection {
		.card {
			background: $green;
			border: none;
			border-radius: 0;

			.card-body {
				position: relative;
				padding: 15px 20px 30px 20px;

				@include media-breakpoint-up(xl) {
					padding: 20px 30px 35px 30px;
				}

				.card-caption {
					.card-title-link {
						position: absolute;
						left: 0;
						right: 30px;
						bottom: 100%;
						max-width: 300px;
						margin: 0 0 30px 0;
						padding: 15px;
						background: rgba($green-dark, 0.6);

						.card-title {
							margin: 0;
							color: $white;
							line-height: 1.05;
							@include font-size(1.5rem);
						}
					}

					.card-subtitle {
						margin: 0;
						color: $red;
						font-size: 14px;
						font-weight: 700;
						font-family: $font-family-primary;
					}

					.card-description {
						margin: 20px 0;
						color: $white;

						strong {
							color: $green-dark;
							@extend .h2;
						}

						ul {
							@extend .custom-list;

							li {
								margin-bottom: 0 !important;
							}
						}
					}
				}

				.card-buttons {
					.card-badge {
						position: absolute;
						right: 15px;
						bottom: calc(100% - 50px);
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100px;
						height: 100px;
						padding: 5px;
						border-radius: 100%;
						background-color: $red;
						color: $white;
						font-size: 12px;
						font-weight: 700;
						text-align: center;
						text-decoration: none;
						overflow: hidden;

						&:hover {
							background-color: $green-dark;
						}
					}
				}
			}
		}
	}

}
