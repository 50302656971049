// footer
.footer {
	position: relative;
	padding: 6vh 0 120px 0;
	background: url('/images/footer-bg.jpg') no-repeat center $black;
	background-size: cover;
	color: $white;
	text-align: center;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	a:not(.btn) {
		color: $white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($black, 0.7);
	}

	// footer-outro
	.footer-outro {
		margin-bottom: 45px;

		.btn {
			padding: 0.375rem 1.5rem;
		}
	}

	// footer-address
	.footer-address {
		margin-bottom: 30px;
		font-size: 16px;
	}

	// footer-partners
	.footer-partners {
		margin-bottom: 100px;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		font-size: 14px;

		ul {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}
}
