// eyecatcher
.eyecatcher::after {
	content: '';
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-image: linear-gradient(rgba($black, .65), rgba($black, 0));
}
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: calc(100vh - 280px);
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 48px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;
	background-color: #cdcdcd;

	// vimeo-video
	.vimeo-video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	// wysiwyg
	.wysiwyg {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 15px;
		color: $white;

		@include media-breakpoint-down(md) {
			display: none;
		}

		h1 {
			color: $white;
			text-align: center;
		}
	}

	.owl-nav,
	.owl-dots {
		display: none;
	}
}
