// mini-sab
.mini-sab {
	z-index: 998;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $green-dark;
	box-shadow: 0 -3px 6px rgba($black, 0.15);
	transition: 0.5s;

	ul {
		li {
			width: 100%;
		}

		a {
			display: block;
			padding: 20px 0 10px 0;
			color: $white;
			font-size: 12px;
			font-weight: 700;
			text-align: center;
			text-decoration: none;

			.list-item-title {
				position: relative;
				z-index: 1;
			}

			&::before {
				content: "\f0f2";
				position: absolute;
				top: -30px;
				left: 0;
				right: 0;
				width: 60px;
				height: 60px;
				line-height: 60px;
				margin: 0 auto;
				border-radius: 100%;
				background: $green-dark;
				color: $white;
				font-size: 26px;
				font-weight: 300;
				text-align: center;
				font-family: $font-awesome;
				transition: 0.5s;
				z-index: 0;
			}

			&:hover {
				background: lighten($green-dark, 5%);

				&::before {
					background: lighten($green-dark, 5%);
				}
			}
		}
	}

	// recranet-element
	.recranet-element {
		background-color: transparent;

		.rn-accommodation-search-form-section {
			border: none !important;
			background-color: transparent !important;

			.rn-accommodation-search-form-section-padding {
				padding: 0;
			}

			.rn-accommodation-search-form-btn {
				min-height: 38px;
				background-color: $white;
				border-color: $white;
				color: $red;
				font-size: 12px;

				&:hover {
					background-color: $green;
					border-color: $green;
					color: $white;
				}
			}

			@media (min-width: 576px) {
				.rn-datepicker-dropdown {
					top: auto;
					bottom: calc(100% - 1px);
				}
			}
		}
	}
}
