// btn
.btn {
	padding: 14px 29px;
	border-radius: 25px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;

	// btn-primary
	&.btn-primary {
		&:hover {
			background-color: $green;
			border-color: $green;
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		color: $red;

		&:hover {
			background-color: $red;
			border-color: $red;
			color: $white;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-secondary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
